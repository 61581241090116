import { $, ResolvedSignal, registerClass } from '../signal';

export class Selectable<T extends Selectable = Selectable<any>> {
  public type = $.signal('SELECTABLE');

  public toJSON() {
    return $.toJSON(this);
  }

  public fromJSON(json: ResolvedSignal<T> = {}) {
    $.fromJSON(json, this);
  }

  public initialFromResolvedValue(resolved: ResolvedSignal<T>) {
    const that = this as any;
    for (const key in resolved) {
      if (that[key]?.initialFromResolvedValue instanceof Function) {
        that[key].initialFromResolvedValue(resolved[key]);
      } else if (that[key]?.set instanceof Function) {
        that[key].set(resolved[key]);
      } else {
        that[key] = resolved[key];
      }
    }
  }
}

registerClass(Selectable);
