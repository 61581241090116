import { ParticipantDto } from '@ay-gosu-party/server-shared';
import { $, ResolvedSignal, registerClass } from '../../signal';
import { Font } from '../font/font.class';
import { Selectable } from '../selectable';
import { Text } from '../text/text.class';

export class WinningList extends Selectable<WinningList> {
  public override type = $.signal('WINNING_LIST');

  public count = $.signal(1);

  public award = new Text({ content: '超值好禮', align: 'center' });

  public subtitle = new Font({ align: 'center' });

  public avatarName = new Font({ align: 'center' });

  public participants = $.signal<ParticipantDto[]>([]);

  public constructor(options: ResolvedSignal<WinningList> = {}) {
    super();
    this.initialFromResolvedValue(options);
  }
}

registerClass(WinningList);
