import { $, ResolvedSignal, registerClass } from '../../signal';
import { Selectable } from '../selectable';
import { Shadow } from '../text/shadow.class';

export class Font extends Selectable<Font> {
  public override type = $.signal('FONT');

  public family = $.signal('Open Sans');
  public bolder = $.signal(false);
  public color = $.signal('#000000');
  public backgroundColor = $.signal('transparent');
  public align = $.signal<string | null>('left');
  public borderRadius = $.signal('0px');
  public shadow = new Shadow();
  public content = $.signal('');

  public constructor(options: ResolvedSignal<Font> = {}) {
    super();
    this.initialFromResolvedValue(options);
  }
}

registerClass(Font);
