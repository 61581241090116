import { Filmstrip } from '../abstract/filmstrip.class';
import { FilmstripType } from '../abstract/filmstrip.type';
import { Module } from '../abstract/module.class';
import { ModuleType } from '../abstract/module.type';
import { Background, Text } from '../object';

export class HeaderFilmstrip extends Filmstrip {
  public override name = '標題';

  public override type = FilmstripType.HEADER;

  public override withMobileInteractive = false;

  public override projection = {
    title: new Text({
      content: '數隻小瑪莉遊戲',
      bolder: true,
      align: 'center',
    }),
    background: new Background({
      type: 'IMAGE',
      src: 'assets/count-mario-bg.jpg',
    }),
  };

  public override mobile = {
    background: new Background({ type: 'COLOR', hex: '#D0D9E4' }),
    title: new Text({ content: ' 等待下一步', align: 'center', bolder: true }),
  };

  public override data = {};

  public override mobileToolbar = {
    options: [],
    selectedOptionIndex: 0,
  };
}

export class HeaderModule extends Module {
  public static override moduleName = '標題';

  public override name = '標題';

  public override type = ModuleType.HEADER;

  public override readonly filmstrips = [new HeaderFilmstrip()];

  public override showInCreateDialogOther = true;

  public override data = {};

  public constructor(module: Partial<HeaderModule> = {}) {
    super();
    this.fromJSON(module);
  }
}
