import { Filmstrip } from '../abstract/filmstrip.class';
import { FilmstripType } from '../abstract/filmstrip.type';
import { Module } from '../abstract/module.class';
import { ModuleType } from '../abstract/module.type';
import { Background, Text } from '../object';
import { Font } from '../object/font/font.class';
import { MultipleLineText } from '../object/multiple-line-text/multiple-line-text.class';
import { $ } from '../signal';

export class DrawCardFilmstrip extends Filmstrip {
  public name: string = '抽卡';

  public override readonly type = FilmstripType.DRAW_CARD;

  public override readonly withMobileInteractive = true;

  public projection = {
    background: new Background({
      type: 'IMAGE',
      src: 'assets/draw-card/background.jpg',
    }),
    award: new Text({
      content: '旺財明信片',
      align: 'center',
    }),
    name: new Font({ align: 'center' }),
    attendeesCount: new Font({ align: 'center' }),
    frontCardStyle: new Background({
      type: 'COLOR',
      hex: '#FFF6E6',
    }),
    backCardStyle: [
      new Background({
        type: 'IMAGE',
        src: 'https://gosubarpublic.blob.core.windows.net/static/2024/03/20/3111/upload/181032_c382665d-a391-493f-8cc7-61dcaed89b92.jpg',
      }),
    ],
  };

  public mobile = {
    background: new Background({ hex: '#D0D9E4' }),
    title: new Text({ content: '恭喜中獎！', align: 'center', bolder: true }),
    card: {
      image: $.signal(
        'https://gosubarpublic.blob.core.windows.net/static/2023/12/05/3111/upload/164657_68df956c-8f18-4420-8233-4f9415b249e8.jpg',
      ),
      useDefaultImage: $.signal(true),
      title: new Text({ content: '恭喜中獎', bolder: true }),
      content: new MultipleLineText({
        content: '請前往櫃檯服務處，兌換禮物！',
      }),
    },
  };

  public data = {};

  public mobileToolbar = {
    options: [],
    selectedOptionIndex: 0,
  };
}

export class DrawCardModule extends Module {
  public static override moduleName = '抽卡';

  public override name = '抽卡';

  public override type = ModuleType.DRAW_CARD;

  public override filmstrips = [new DrawCardFilmstrip()];

  public override showInCreateDialogOther = true;

  public override showInCreateDialog = false;

  public override data = {};

  public constructor(module: Partial<DrawCardModule> = {}) {
    super();
    this.fromJSON(module);
  }
}
