import { Filmstrip } from '../abstract/filmstrip.class';
import { FilmstripType } from '../abstract/filmstrip.type';
import { Module } from '../abstract/module.class';
import { ModuleType } from '../abstract/module.type';
import { Background } from '../object';
import { Font } from '../object/font/font.class';
import { $ } from '../signal';

export class SurveyFilmstrip extends Filmstrip<SurveyModule> {
  public name = '民意調查';

  public override readonly type = FilmstripType.SURVEY;

  public override readonly withMobileInteractive = true;

  public projection = {
    background: new Background({
      type: 'IMAGE',
      src: 'assets/count-mario/question.jpg',
    }),
    // 每個問題的投票數 字體樣式
    questionNumberOfVoteFont: new Font(),
    // 問題 字體樣式
    questionFont: new Font({ align: 'left' }),
    // 右上角投票數 字體樣式
    numberOfVote: new Font({ align: 'center' }),
    // 選項 字體樣式
    option: new Font({ align: 'left' }),
  };

  public mobile = {
    background: new Background({
      type: 'COLOR',
      hex: '#D2D9E3',
    }),
    question: new Font(),
    option: new Font({
      align: 'center',
      borderRadius: '8px',
      backgroundColor: '#FFFFFF',
      shadow: {
        offsetX: 1,
        offsetY: 1,
        blur: 5,
        color: '#000000',
        opacity: 0.25,
      },
    }),
  };

  public data = {};

  public mobileToolbar: {
    options: string[];
    selectedOptionIndex: number;
  } = {
    options: [],
    selectedOptionIndex: 0,
  };
}

export class SurveyModule extends Module {
  public static override moduleName = '民意調查';

  public override name = '民意調查';

  public override readonly type = ModuleType.SURVEY;

  public override readonly filmstrips = [new SurveyFilmstrip()];

  public override showInCreateDialog = true;

  public override data = {
    // 問題版型(1~4題)
    formatType: $.signal(1),
    // 問題列表
    questions: $.signal([
      {
        isDoughnut: false,
        question: '',
        options: [
          { text: '選項 1', color: '#3D604F' },
          { text: '選項 2', color: '#092B0E' },
          { text: '選項 3', color: '#FFEBC5' },
        ],
      },
    ]),
  };

  public constructor(module: Partial<SurveyModule> = {}) {
    super();
    this.fromJSON(module);
  }
}
