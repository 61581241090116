import { Filmstrip } from '../abstract/filmstrip.class';
import { FilmstripType } from '../abstract/filmstrip.type';
import { Module } from '../abstract/module.class';
import { ModuleType } from '../abstract/module.type';
import { Background, Text } from '../object';
import { Font } from '../object/font/font.class';
import { $ } from '../signal';

export class FooterFilmstrip extends Filmstrip {
  public name: string = '活動結束';

  public override readonly type = FilmstripType.FOOTER;

  public override readonly withMobileInteractive = true;

  public projection = {
    background: new Background({
      type: 'IMAGE',
      src: 'assets/count-mario/background.jpg',
    }),
    title: new Text({
      content: '活動結束',
      align: 'center',
    }),
    link: new Font({ align: 'center' }),
  };

  public mobile = {
    background: new Background({
      type: 'COLOR',
      hex: '#D2D9E3',
    }),
    title: new Text({
      content: '感謝您的參與',
      align: 'center',
      bolder: true,
    }),
    link: new Font({
      backgroundColor: '#303030',
      color: '#FFFFFF',
      align: 'center',
      borderRadius: '4px',
    }),
  };

  public data = {};

  public mobileToolbar = {
    options: [],
    selectedOptionIndex: 0,
  };
}

export class FooterModule extends Module {
  public static override moduleName = '頁尾';

  public override name = '頁尾';

  public override type = ModuleType.FOOTER;

  public override filmstrips = [new FooterFilmstrip()];

  public override showInCreateDialog = false;

  public override data = {
    links: $.signal<Link[]>([
      { url: '', label: 'LINE' },
      { url: '', label: 'Facebook' },
      { url: '', label: 'Youtube' },
    ]),
  };

  public constructor(module: Partial<FooterModule> = {}) {
    super();
    this.fromJSON(module);
  }
}

export class Link {
  public url: string = '';
  public label: string = '';
}
