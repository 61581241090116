import { $, ResolvedSignal, registerClass } from '../../signal';
import { Font } from '../font/font.class';

/** 如果該文字不會被再多個畫面重複使用，這時當作樣式處理 */
export class Text extends Font {
  public override type = $.signal('TEXT');

  public placeholder = $.signal('輸入內容...');

  public constructor(options: ResolvedSignal<Text> = {}) {
    super();
    this.initialFromResolvedValue(options);
  }
}

registerClass(Text);
